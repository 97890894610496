@import '../../core.css';

.header {
  margin-top: calc(var(--container-padding-top) * -1);
  margin-inline: calc(var(--container-padding-inline) * -1);
  padding: var(--container-padding-top) var(--container-padding-inline) 0;
}

.menu {
  display: flex;
  margin-inline: -20px;
  margin-bottom: -1px;
  margin-top: 40px;
}

@media (--breakpoint-mobile) {
  .menu {
    margin-inline: -12px;
    margin-top: 12px;
  }
}

.menuItem {
  display: block;
  padding: 12px 20px 8px;
  font: var(--font-body-l-b);
  color: var(--color-base-07);
  border-bottom: 1px solid transparent;
}

.menuItem:hover {
  color: var(--color-base-09);
}

@media (--breakpoint-mobile) {
  .menuItem {
    display: block;
    padding: 12px 12px 8px;
    font: var(--font-body-m-b);
  }
}

.menuItem_active {
  color: var(--color-base-09);
  border-bottom: 2px solid var(--color-primary-500);
}

.menuItem_pending {
  pointer-events: none;
}

.content {
  margin-top: 20px;
}

.emptyMessage {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
}

@media (--breakpoint-mobile) {
  .emptyMessage {
    padding-top: 0;
  }
}
