@import '../core.css';

.content {
  display: grid;
  padding-top: 40px;
}

@media (--breakpoint-mobile) {
  .content {
    padding-top: 32px;
  }
}

.header {
  margin-top: calc(var(--container-padding-top) * -1);
  margin-inline: calc(var(--container-padding-inline) * -1);
  padding: var(--container-padding-top) var(--container-padding-inline);
}

@media (--breakpoint-mobile) {
  .header {
    padding-block: 20px;
  }
}

.investmentsOverview {
  margin-bottom: 20px;
}

@media (--breakpoint-mobile) {
  .investmentsOverview {
    margin-bottom: 12px;
  }
}

.investmentsOverviewHeading {
  color: var(--color-base-09);
  font: var(--font-headline-h3);
  margin-bottom: 20px;
}

.widgetsContainer {
  margin-bottom: -20px;
}

.column1 {
  float: left;
  width: calc(68% - 10px);
  margin-bottom: 20px;
}

.column2 {
  float: right;
  width: calc(32% - 10px);
  margin-bottom: 20px;
}

@media (--breakpoint-tablet) {
  .column1,
  .column2 {
    width: 100%;
  }
}
