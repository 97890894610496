@import '../core.css';

.vendorGroupList {
  margin-top: 40px;
  display: grid;
  gap: 20px;
}

.vendorAnchor {
  position: relative;
  top: -68px;
}

.vendorGroupHeader {
  display: grid;
  column-gap: 4px;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'logo name'
    'logo total';
}

.vendorGroupAvatar {
  grid-area: logo;
  border: 1px solid var(--color-base-03);
  width: 33px;
  height: 33px;
  border-radius: 50%;
  object-fit: cover;
}

.vendorGroupName {
  grid-area: name;
  font: var(--font-body-m);
}

.vendorGroupTotal {
  grid-area: total;
  font: var(--font-body-l-b);
}

.vendorProductGroups {
  display: grid;
  row-gap: 8px;
}

.mobileVendorProductGroups {
  display: block;
}

.mobileVendorProductGroup {
  display: grid;
  row-gap: 8px;
}

.card {
  --container-padding-inline: 12px;

  padding-inline: var(--container-padding-inline);
  background-color: var(--color-base-00);
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  border-radius: 12px;
  overflow: hidden;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  padding-block: 12px;
  font: var(--font-body-m-b);
}

.productBadge {
  display: inline-block;
  border-radius: 4px;
  background: var(--color-primary-50);
  color: var(--color-primary-500);
  padding: 5px 2px;
  font: var(--font-body-s);
  margin-top: 12px;
  margin-bottom: 8px;
}

.vendorGroupNameSkeleton {
  margin-top: 37px;
  width: 142px;
  height: 38px;
}

.productBadgelSkeleton {
  margin-top: 12px;
  width: 76px;
  height: 24px;
}

.cardSkeleton {
  margin-top: 8px;
  padding-block: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.productSkeleton {
  height: 24px;
}

.productSkeleton:first-child {
  height: 16px;
}
