.accountButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.accountButtonAvatar {
  flex: 0 0 auto;
}

.accountButtonLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
