.coingeckoBadge {
  margin-top: 12px;
  text-align: right;
}

.coingeckoBadge .coingeckoBadgeLink {
  font-size: 9px;
  font-weight: 400;
  line-height: 14px;
  color: var(--color-base-05);
}
