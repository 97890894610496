.root {
  display: block;
  margin-block-start: 4px;
  color: var(--color-base-06);
  font: var(--font-body-s);
}

.root_isError {
  color: var(--color-system-error);
}
