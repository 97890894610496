@import '../core.css';

.root {
  margin-block-end: 20px;
}

.caption {
  margin-block-end: 8px;
  color: var(--color-base-07);
  font: var(--font-body-m);
}

.heading {
  font: var(--font-headline-h2);
}

@media (--breakpoint-mobile) {
  .heading {
    font: var(--font-headline-h3);
  }
}

.headingSkeleton {
  width: 152px;
  height: 28px;
}

@media (--breakpoint-mobile) {
  .headingSkeleton {
    width: 130px;
    height: 24px;
  }
}
