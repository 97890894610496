.button {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 8px;
  flex-grow: 1;
  align-items: center;
}

.optionLabel {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 40px;
}

.optionLabelName {
  flex: 1 1 0;
}

.optionLabelAmount {
  display: grid;
  row-gap: 8px;
  text-align: end;
}

.optionLabelAmountUsd {
  color: var(--color-base-06);
}
