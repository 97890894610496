@import '../core.css';

.assetTable {
  white-space: nowrap;
  margin-bottom: calc(var(--container-padding-block) * -1);
}

.assetList {
  display: none;
}

@media (--breakpoint-mobile) {
  .assetTable {
    display: none;
  }

  .assetList {
    display: block;
  }
}

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 2px;
}

.skeletonItem {
  height: 24px;
}

@media (--breakpoint-mobile) {
  .skeleton {
    padding-top: 12px;
  }

  .skeletonItem {
    height: 38px;
  }
}
