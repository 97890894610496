@import '../core.css';

.separator {
  border: 1px none var(--color-base-03);
  border-block-start-style: solid;
}

.blockchainLabel {
  display: flex;
  align-items: center;
  color: var(--color-base-09);
  font: var(--font-body-l-b);
}

.blockchainLabelIcon {
  margin-inline-end: 8px;
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.root {
  display: grid;
  row-gap: 20px;
  margin-inline: auto;
  max-width: 550px;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: start;
  gap: 4px;
}

.backButton,
.editButton {
  margin-block-start: 2px;
}

.backButton {
  color: inherit;
  font-size: 24px;
}

.name {
  margin-inline: auto;
  font: var(--font-headline-h2);
}

.editButton {
  padding: 0;
  border: 0;
  background: none;
  font-size: 24px;
}

.card {
  border-radius: 12px;
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  background: var(--color-base-00);
}

.cardSection {
  padding: 20px;
}

@media (--breakpoint-mobile) {
  .cardSection {
    padding: 12px;
  }
}

.cardTitle {
  font: var(--font-headline-h3);
}

.cardDescription {
  margin-block-start: 12px;
  color: var(--color-base-07);
  font: var(--font-body-m);
}

.publicInfoSubheading {
  margin-block-end: 22px;
}

.publicInfoItemList {
  display: grid;
  row-gap: 20px;
}

.publicInfoItem {
  display: grid;
  row-gap: 8px;
}

.publicInfoItemName {
  color: var(--color-base-07);
}

.privateInfoSubheading {
  margin-block-end: 12px;
  color: var(--color-base-07);
  font: var(--font-body-l);
}

.privateInfoKeyList {
  display: grid;
  row-gap: 12px;
}

.revealButton {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  padding: 0;
  width: 100%;
  background: none;
  font: var(--font-body-l-b);
  text-align: start;
}

.revealButtonIcon {
  color: var(--color-primary-500);
  font-size: 24px;
}

.passwordModalAttentionBox {
  border-radius: 8px;
  overflow: hidden;
  background: var(--color-base-01);
}

.passwordModalAttentionBoxHeader {
  display: flex;
  justify-content: center;
  padding: 8px;
  background: var(--color-system-error);
  color: var(--color-base-00);
  font-size: 24px;
}

.passwordModalAttentionBoxBody {
  padding: 12px 10px;
  font: var(--font-body-m);
  text-align: center;
}

.passwordModalEnterPasswordText {
  margin-block: 20px;
  font: var(--font-body-m);
  text-align: center;
}

.revealModalDescription {
  font: var(--font-body-m);
  text-align: center;
}

.revealModalRevealableContent {
  margin-block: 20px;
}

.revealModalCopyButtonWrapper {
  text-align: center;
}

.revealModalCopyButton {
  width: 200px;
}

.modalBottomButton {
  margin-block-start: 20px;
}
