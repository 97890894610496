.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 0;
  padding: 0;
  border-radius: 8px;
  transition-property: background-color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  user-select: none;
}

.root_block {
  width: 100%;
}

.root_size_small {
  padding-inline: 7px;
  height: 30px;
  font: var(--font-body-m-b);
}

.root_size_small.root_iconOnly {
  padding-inline: 2px;
}

.root_size_big {
  padding-inline: 16px;
  height: 40px;
  font: var(--font-body-l-b);
}

.root_size_big.root_iconOnly {
  padding-inline: 7px;
}

.root_error_off {
  --button-main-color: var(--color-primary-500);
}

.root_error_on {
  --button-main-color: var(--color-system-error);
}

.root_state_primary {
  background: var(--button-main-color);
  color: var(--color-base-00);
}

.root_state_primary.root_error_off {
  box-shadow: 0 2px 16px rgba(31, 90, 246, 0.4);
}

.root_state_primary.root_error_off:hover {
  box-shadow: 0 1px 4px rgba(31, 90, 246, 0.4);
  background-color: var(--color-primary-800);
}

.root_state_primary.root_error_on:hover {
  background-color: #c72a34;
}

.root_state_outlined {
  border: 1px solid;
  background: none;
  color: var(--button-main-color);
}

.root_state_outlined.root_error_off:hover {
  background-color: rgba(31, 90, 246, 0.05);
}

.root_state_outlined.root_error_on:hover {
  background-color: rgba(248, 33, 58, 0.05);
}

.root_state_text {
  color: var(--button-main-color);
  background: none;
}

.root_state_disabled {
  border-color: var(--color-base-04);
  background-color: var(--color-base-04);
  color: var(--color-base-00);
  opacity: 1;
  cursor: not-allowed;
}

.startIcon {
  font-size: 24px;
}
