.root {
  border-radius: 8px;
  background: var(--color-base-02)
    linear-gradient(
      to right,
      var(--color-base-02) 8%,
      var(--color-base-00) 18%,
      var(--color-base-02) 33%
    )
    100% 0 / 200% 100% no-repeat fixed;
  animation: 2s glow ease-out infinite;
}

@keyframes glow {
  to {
    background-position-x: -100%;
  }
}
