@import '../core.css';

.helpText {
  font: var(--font-body-m);
  text-align: center;
  margin-block-end: 20px;
}

.wordsToCheck {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.wordsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-block: 40px;
}

.pill {
  display: grid;
  grid-template-rows: 32px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  border: 1px solid var(--color-base-02);
  background: var(--color-base-01);
  font: var(--font-body-m);
  opacity: 1;
  transition-property: opacity, background, border-right-color;
  transition-duration: 0.2s;
}

.pillWithIndex {
  grid-template-columns: 20px auto;
  justify-content: start;
}

.pillIndex {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid var(--color-base-02);
  color: var(--color-base-06);
}

.pillHidden {
  opacity: 0;
  pointer-events: none;
}

.pillSelected {
  color: var(--color-base-00);
  background: var(--color-primary-500);
}

.pillSelected .pillIndex {
  border-right-color: var(--color-primary-50);
  color: var(--color-primary-50);
}

.pillError {
  border-color: var(--color-system-error);
}

.errorText {
  font: var(--font-body-m);
  color: var(--color-system-error);
  text-align: center;
  margin-block-end: 20px;
}
