@import '../core.css';

.passwordScreenHeading {
  text-align: center;
  font: var(--font-headline-h2);
  margin-bottom: 20px;
}

.passwordForm {
  max-width: 360px;
  margin: auto;
  border-radius: 12px;
  background: var(--color-base-00);
  box-shadow: 0 4px 40px 0 rgba(150, 150, 166, 0.12);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (--breakpoint-mobile) {
  .passwordForm {
    padding: 12px;
  }
}

.passwordFormDescription {
  text-align: center;
  font: var(--font-body-m);
}

.passwordFormButtons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.forgotPasswordLink {
  margin-top: -8px;
  align-self: center;
  font: var(--font-body-s-b);
}
