.tooltipRoot {
  border-radius: 4px;
  background: rgba(39, 39, 50, 0.8);
  color: var(--color-base-00);
  pointer-events: none;
  font: var(--font-body-s);
}

.tooltipContent {
  padding: 4px 8px;
}

.tooltipArrow {
  color: rgba(39, 39, 50, 0.8);
}

[data-popper-placement^='top'] .tooltipArrow {
  bottom: -8px;
}

[data-popper-placement^='bottom'] .tooltipArrow {
  top: -8px;
}
[data-popper-placement^='bottom'] .tooltipArrowInner {
  transform: rotate(180deg);
}

[data-popper-placement^='left'] .tooltipArrow {
  right: -8px;
}
[data-popper-placement^='left'] .tooltipArrowInner {
  transform: rotate(270deg);
  translate: 3px;
}

[data-popper-placement^='right'] .tooltipArrow {
  left: -8px;
}
[data-popper-placement^='right'] .tooltipArrowInner {
  transform: rotate(90deg);
  translate: -3px;
}
