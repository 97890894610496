@import '../core.css';

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  gap: 33.3333333333px;
}

.cardImage {
  aspect-ratio: 1;
  border-radius: 12px;
  object-fit: cover;
}

.cardCaption {
  margin-top: 8px;
  color: var(--color-base-09);
}

.cardLabel {
  display: block;
  overflow: hidden;
  font: var(--font-body-m-b);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cardIssuerLabel {
  display: block;
  overflow: hidden;
  font: var(--font-body-s);
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (--breakpoint-mobile) {
  .cards {
    grid-template-columns: repeat(auto-fill, 126px);
    gap: 12px 20px;
  }
}

.skeletonItem {
  display: flex;
  flex-direction: column;
}

.imageSkeleton {
  height: 150px;
  border-radius: 12px;
}

@media (--breakpoint-mobile) {
  .imageSkeleton {
    height: 126px;
  }
}

.labelSkeleton {
  width: 104px;
  height: 16px;
  margin-top: 8px;
}

.issuerSkeleton {
  width: 86px;
  height: 12px;
  margin-top: 2px;
}
