@import '../core.css';

.root {
  border-top: 1px solid var(--color-base-03);
  background: var(--color-base-01);
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
  grid-template-areas: 'logo content';
  min-height: 64px;
}

.logoContainer {
  grid-area: logo;
  display: flex;
  align-items: center;
  padding-left: 28px;
}

.contentContainer {
  grid-area: content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
}

.footerMenu_container {
  display: flex;
  flex-wrap: wrap;
}

@media (--breakpoint-tablet) {
  .root {
    grid-template-columns: 1fr;
    grid-template-areas:
      'logo'
      'content';
  }

  .logoContainer {
    padding-top: 20px;
  }

  .contentContainer {
    padding-bottom: 40px;
    padding-left: 28px;
  }
}

@media (--breakpoint-mobile) {
  .logoContainer {
    padding: 20px 12px 0 12px;
  }

  .contentContainer {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.content {
  margin-right: auto;
}

@media (--breakpoint-tablet) {
  .content {
    margin-top: 40px;
    margin-right: 60px;
  }
}

.logo {
  width: 90px;
  display: block;
  margin-right: auto;
}

.logo img {
  width: 100%;
}

.footerMenu {
  margin-bottom: -4px;
  flex-shrink: 0;
}

@media (--breakpoint-tablet) {
  .footerMenu {
    margin-top: 40px;
  }
}

.footerMenu:not(:last-child) {
  margin-right: 60px;
}

.footerMenu_title {
  font: var(--font-body-s);
  color: var(--color-base-05);
}

.footerMenu_list {
  display: flex;
  margin-top: 4px;
}

.footerMenu_item {
  font: var(--font-body-m);
  color: var(--color-base-07);
  white-space: nowrap;
}

.footerMenu_item:not(:last-child) {
  margin-right: 24px;
}

.footerMenu_item a {
  color: inherit;
}
