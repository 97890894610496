.helpText {
  font: var(--font-body-m);
  text-align: center;
}

.seedWrapper {
  margin-block: 20px;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.copyButton {
  width: 200px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--color-base-02);
  background: var(--color-base-01);
  font: var(--font-body-s);
  color: var(--color-base-07);
  margin-block-start: 20px;
  margin-block-end: 8px;
}

.checkboxIconBorder {
  order: -1;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--color-base-05);
}

.checkboxIcon {
  width: 24px;
  height: 24px;
  margin: -1px;
  border-radius: 4px;
  color: var(--color-primary-500);
}

.checkboxInput {
  appearance: none;
}

.checkboxInput:checked + .checkboxIconBorder {
  border-color: var(--color-primary-500);
  background: var(--color-primary-50);
}

.checkboxInput:not(:checked) + .checkboxIconBorder > .checkboxIcon {
  display: none;
}
