@import '../core.css';

.pageHeader {
  background-color: var(--color-base-00);
  border-bottom: 1px solid var(--color-base-03);
}

.pageHeaderTitle {
  font: var(--font-body-m);
  color: var(--color-base-07);
}

.pageHeaderValue {
  margin-top: 8px;
  font: var(--font-headline-h1);
}

@media (--breakpoint-mobile) {
  .pageHeaderValue {
    font: var(--font-headline-h2);
  }
}

.skeleton {
  margin-top: 8px;
  width: 100%;
  max-width: 282px;
  height: 38px;
}

@media (--breakpoint-mobile) {
  .skeleton {
    height: 28px;
  }
}
