@import '../core.css';

.stack {
  display: flex;
  gap: 2px;
  padding: 0 5px;
  background: var(--color-base-00);
  border: 1px solid var(--color-base-03);
  border-radius: 10px;
  height: 20px;
}

.stackItem {
  padding: 5px 0;
  min-width: 16px;
  transition: opacity ease-in-out 0.2s;
}

.stackItem_dimmed {
  opacity: 0.4;
}

.stackItemFill {
  height: 100%;
  border-radius: 6px;
}

.dApps {
  margin-top: 16px;
}

.otherDApps {
  margin-top: 20px;
  border: 1px solid var(--color-base-03);
  background-color: var(--color-base-00);
  border-radius: 8px;
  padding: 8px;
}

.dAppsGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 14px;
}

@media (--breakpoint-tablet) {
  .dAppsGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--breakpoint-mobile) {
  .dAppsGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width <= 500px) {
  .dAppsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.dApp {
  display: grid;
  align-items: center;
  gap: 4px;
  grid-template-columns: auto 1fr;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 8px;
  text-align: left;
  pointer-events: all;
  width: 100%;
  background-color: transparent;
  color: var(--color-base-99);
  cursor: default;
}

.dApp_active {
  background-color: var(--color-base-00);
  border: 1px solid var(--color-base-03);
  box-shadow: 0 2px 16px rgba(31, 90, 246, 0.2);
}

.clickable {
  cursor: pointer;
}

.dAppLogo {
  width: 32px;
  height: 32px;
  position: relative;
  background: var(--color-base-04);
  border-radius: 50%;
}

.dAppLogo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dAppLogoBadge {
  width: 8px;
  height: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1px solid var(--color-base-01);
}

.dAppContent {
  overflow: hidden;
}

.dAppTitle {
  overflow: hidden;
  font: var(--font-body-l);
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (--breakpoint-mobile) {
  .dAppTitle {
    font: var(--font-body-s);
  }
}

.dAppValue {
  overflow: hidden;
  color: var(--color-base-09);
  font: var(--font-body-l-b);
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (--breakpoint-mobile) {
  .dAppValue {
    font: var(--font-body-s-b);
  }
}

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.stackSkeleton {
  height: 20px;
}

.itemsSkeleton {
  width: 100%;
  max-width: 170px;
  height: 50px;
}

@media (--breakpoint-mobile) {
  .itemsSkeleton {
    height: 44px;
  }
}
