.root {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  border: 0;
  padding: 0;
  background: none;
  text-align: start;
}

.root_block {
  justify-content: space-between;
  width: 100%;
}

.text {
  font: var(--font-body-l);
}

.icon {
  flex-shrink: 0;
  font-size: 24px;
  color: var(--color-primary-500);
}
