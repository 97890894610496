.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.item {
  display: grid;
  grid-template-columns: 20px auto;
  grid-template-rows: 32px;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  border: 1px solid var(--color-base-02);
  background: var(--color-base-01);
  font: var(--font-body-m);
}

.itemIndex {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid var(--color-base-02);
  color: var(--color-base-06);
}
