@import '../core.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 500px;
}

.senderCard,
.widgetCard {
  background: var(--color-base-00);
  box-shadow: 0px 4px 40px rgba(150, 150, 166, 0.12);
  border-radius: 12px;
}

.senderCard {
  position: relative;
  padding: 20px;
}

@media (--breakpoint-mobile) {
  .senderCard {
    padding: 12px;
  }
}

.senderLabel {
  display: flex;
  justify-content: space-between;
  font: var(--font-body-s);
  color: var(--color-base-06);
}

.senderOptionLabel {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}

.senderOptionAddress {
  color: var(--color-base-06);
}

.widgetCard {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding-inline: 12px;
  min-height: 515px;
  position: relative;
}

@media (--breakpoint-mobile) {
  .widgetCard {
    padding-inline: 4px;
  }
}
