@import '../../core.css';

.form {
  display: flex;
  flex-direction: column;
}

.helpText {
  font: var(--font-body-m);
  text-align: center;
}

.nameInput {
  margin-block-start: 20px;
}

.addressList {
  margin-block-start: 12px;
}

.warningMessage {
  margin-block-start: 20px;
  color: var(--color-system-warning);
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.actionButtonWrapper {
  margin-block-start: 20px;
}

.statusRoot {
  display: grid;
  justify-items: center;
}

.statusText {
  text-align: center;
  font: var(--font-body-l-b);
}

.statusIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 20px;
  margin-block: 40px;
  background: var(--color-base-00);
  box-shadow: 0px 8px 16px 0px rgba(39, 39, 50, 0.08);
}

.sadIcon {
  font-size: 40px;
}

.cleanButton {
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-block-start: 12px;
  width: 100%;
  border: 0;
  background: none;
  color: var(--color-primary-500);
  font: var(--font-body-l-b);
}
