.blockchainButton,
.blockchainOption {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.blockchainButton {
  height: 24px;
  color: var(--color-base-07);
  font: var(--font-body-l-b);
}

.blockchainOption {
  height: 20px;
}

.blockchainOptionIcon {
  width: 20px;
  height: 20px;
}

.blockchainOptionIcon img {
  display: inline-block;
  vertical-align: top;
  object-fit: contain;
}

.blockchainName {
  color: var(--color-primary-500);
}
