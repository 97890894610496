@import '../core.css';

.root {
  display: grid;
  grid-template-areas:
    'header header'
    'image details';
  grid-template-columns: minmax(296px, 360px) 1fr;
  gap: 20px 40px;
  max-width: 930px;
}

@media (--breakpoint-tablet) {
  .root {
    grid-template-areas:
      'header'
      'image'
      'details';
    grid-template-columns: 1fr;
  }
}

.header {
  grid-area: header;
}

.headerTitle {
  font: var(--font-headline-h1);
}

.details {
  grid-area: details;
  display: grid;
  gap: 20px;
  align-content: start;
}

.detailsItem {
  display: grid;
  gap: 8px;
}

.detailsItemTitle {
  font: var(--font-body-m);
  color: var(--color-base-07);
}

.detailsItemContent {
  font: var(--font-body-l);
}

.addressContainer {
  display: flex;
  align-items: center;
}

.viewOnLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.viewOnLink {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  background: var(--color-base-00);
  border: 1px solid var(--color-base-03);
  border-radius: 8px;
  font: var(--font-body-m);
  color: var(--color-base-99);
}

.viewOnLink:hover {
  color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}

.viewOnLinkImageContainer {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-base-01);
  border-radius: 50%;
}

.cardImageContainer {
  grid-area: image;
}

.cardImage {
  width: 100%;
  max-width: 360px;
}
