.helpText {
  font: var(--font-body-m);
  text-align: center;
  margin-block-end: 20px;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 380px;
}

.pasteButtonWrapper {
  margin: 12px auto 0;
  max-width: 200px;
}

.nameInput {
  margin-block-start: 35px;
}

.addressList {
  margin-block-start: 12px;
}

.errorMessage,
.warningMessage {
  margin-block: 20px;
  color: var(--color-system-error);
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.warningMessage {
  color: var(--color-system-warning);
}

.actionButtonWrapper {
  margin-block-start: 20px;
}
