.helpText {
  font: var(--font-body-m);
  text-align: center;
  margin-block-end: 20px;
}

.addressList {
  margin-block-start: 12px;
  margin-block-end: 20px;
}
