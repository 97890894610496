@import '../../core.css';

.root {
  text-align: center;
  margin: 40px 50px 0;
  color: var(--color-base-07);
}

@media (--breakpoint-mobile) {
  .root {
    margin-block-start: 20px;
  }
}

.link:hover {
  text-decoration: underline;
}
