.root {
  position: relative;
}

.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: -10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  opacity: 100%;
  visibility: visible;
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.backdropHidden {
  opacity: 0;
  visibility: hidden;
}

.showButton {
  width: 200px;
}
