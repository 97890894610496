@import '../core.css';

.sendPageTitle {
  font: var(--font-headline-h1);
  margin-right: auto;
  margin-bottom: 20px;
}

@media (--breakpoint-mobile) {
  .sendPageTitle {
    font: var(--font-headline-h2);
  }
}

.sendSpinnerWrapper {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
