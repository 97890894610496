.logoContainer {
  font-size: 24px;
  width: 24px;
  object-fit: contain;
  position: relative;
}

.networkBadge {
  position: absolute;
  inset-inline-end: -2px;
  inset-block-end: -2px;
  border: 1px solid var(--color-base-00);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: var(--color-base-01);
}
