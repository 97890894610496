.root {
  border: 1px solid var(--color-base-03);
  border-radius: 8px;
  background: var(--color-base-00);
}

.item {
  display: flex;
  align-items: flex-start;
  padding: 8px;
  padding-inline-end: 18px;
  color: var(--color-base-06);
  font: var(--font-body-s);
}

.item + .item {
  border-block-start: 1px solid var(--color-base-03);
}

.item::before {
  content: '';
  margin-inline-end: 4px;
  width: 16px;
  height: 16px;
  background: center / 16px 16px no-repeat;
}

.item_ethereum::before {
  background-image: url(../../icons/ethereum.svg);
}

.item_waves::before {
  background-image: url(../../icons/waves.svg);
}

.text {
  margin-block-start: 1px;
}
