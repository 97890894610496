@import '../core.css';

.investmentListMobile {
  display: none;
}

@media (--breakpoint-mobile) {
  .investmentListDesktop {
    display: none;
  }

  .investmentListMobile {
    display: block;
  }
}
