@import '../../core.css';

.root {
  display: grid;
  row-gap: 20px;
  margin: auto;
  max-width: 360px;
}

.header {
  display: flex;
  align-items: center;
}

.backButton {
  flex: 0 0 auto;
  color: inherit;
  font-size: 24px;
}

.heading {
  flex: 1 1 auto;
  font: var(--font-headline-h2);
  text-align: center;
}

.header::after {
  content: '';
  flex: 0 99999 24px;
}

.children {
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  background: var(--color-base-00);
}

@media (--breakpoint-mobile) {
  .children {
    padding: 12px;
  }
}
