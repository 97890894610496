@import '../core.css';

.container {
  margin: auto;
  max-width: 360px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header::after {
  content: '';
  flex: 0 99999 24px;
}

.heading {
  flex: 1 1 auto;
  font: var(--font-headline-h2);
  text-align: center;
}

.backButton {
  flex: 0 0 auto;
  font-size: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  background: var(--color-base-00);
  text-align: center;
  font: var(--font-body-m);
}

@media (--breakpoint-mobile) {
  .form {
    padding: 12px;
  }
}

.formHeading {
  font: var(--font-body-l-b);
}

.alert {
  background-color: var(--color-base-01);
  border-radius: 8px;
  margin-inline: -8px;
  overflow: hidden;
}

@media (--breakpoint-mobile) {
  .alert {
    margin-inline: 0;
  }
}

.alertHeader {
  background-color: var(--color-system-error);
  padding-block: 8px;
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: var(--color-base-00);
}

.alertContent {
  padding: 12px 8px;
  font: var(--font-body-m);
}

.input {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 2px;
}
