.group {
  display: grid;
  row-gap: 8px;
}

.separator {
  margin-block: 12px;
  color: var(--color-base-07);
  font: var(--font-body-s-b);
  text-align: center;
}

.button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--color-base-03);
  padding: 8px;
  width: 100%;
  border-radius: 12px;
  background: var(--color-base-01);
  color: inherit;
  text-align: start;
  transition: box-shadow 200ms;
}

.button:hover {
  box-shadow: 0px 2px 16px rgba(31, 90, 246, 0.2);
}

.buttonIcon {
  flex: 0 0 32px;
  padding: 4px;
  height: 32px;
  border-radius: 8px;
  background: var(--color-base-00);
  opacity: 0.5;
  transition: opacity 200ms;
}

.button:hover .buttonIcon {
  opacity: 1;
}

.buttonText {
  flex: 1 1 auto;
  font: var(--font-body-l-b);
}

.buttonChevronIcon {
  flex: 0 0 auto;
  font-size: 24px;
  color: var(--color-base-06);
  transition: color 200ms;
}

.button:hover .buttonChevronIcon {
  color: var(--color-primary-500);
}
