@import './fonts.css';
@import 'normalize.css';

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  accent-color: var(--color-primary-500);
  -webkit-tap-highlight-color: transparent;
}

*:focus-visible {
  outline: 2px solid var(--color-primary-200);
  outline-offset: 1px;
}

::selection {
  background: var(--color-primary-200);
  color: var(--color-base-01);
}

:root {
  --min-screen-width: 320px;

  --color-neutral-white: #fff;
  --color-neutral-black: #000;

  --color-base-00: #ffffff;
  --color-base-01: #f9f9fd;
  --color-base-02: #f4f4f9;
  --color-base-03: #eaeaf3;
  --color-base-04: #e0e0ee;
  --color-base-05: #c6c6de;
  --color-base-06: #9c9cb6;
  --color-base-07: #6e6e81;
  --color-base-08: #424250;
  --color-base-09: #272732;
  --color-base-99: #000000;

  --color-primary-50: #e9efff;
  --color-primary-100: #b6c7f8;
  --color-primary-200: #a1affc;
  --color-primary-300: #768ffb;
  --color-primary-400: #5173f9;
  --color-primary-500: #1f5af6;
  --color-primary-600: #184fea;
  --color-primary-700: #0044dd;
  --color-primary-800: #0039d2;
  --color-primary-900: #0021c1;

  --color-danger-50: #fcecf0;
  --color-danger-100: #f7cfd5;
  --color-danger-200: #ec9da0;
  --color-danger-300: #e3777b;
  --color-danger-400: #eb575c;
  --color-danger-500: #eb4444;
  --color-danger-600: #f8213a;
  --color-danger-700: #d33e31;
  --color-danger-800: #c72a34;
  --color-danger-900: #ba2629;

  --color-system-error: #f8213a;
  --color-system-error-container: #fff2f4;
  --color-system-success: #009e3f;
  --color-system-success-container: #e6fff3;
  --color-system-warning: #fe8a04;
  --color-system-warning-container: #fff7e6;

  --rubik-font-stack: Rubik, Frutiger, 'Frutiger Linotype', Univers, Calibri,
    'Gill Sans', 'Gill Sans MT', 'Myriad Pro', Myriad, 'DejaVu Sans Condensed',
    'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva, 'Helvetica Neue',
    Helvetica, Arial, sans-serif;

  --font-headline-h1: 500 32px/38px var(--rubik-font-stack);
  --font-headline-h2: 500 24px/28px var(--rubik-font-stack);
  --font-headline-h3: 500 20px/24px var(--rubik-font-stack);

  --font-body-l: 16px/19px var(--rubik-font-stack);
  --font-body-l-b: 500 var(--font-body-l);

  --font-body-m: 14px/16px var(--rubik-font-stack);
  --font-body-m-b: 500 var(--font-body-m);

  --font-body-s: 12px/14px var(--rubik-font-stack);
  --font-body-s-b: 500 var(--font-body-s);

  --sidebar-width: 220px;
}

.root {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  min-width: var(--min-screen-width);
  height: inherit;
  background: var(--color-base-01);
  color: var(--color-base-09);
  font: 1em / calc(14 / 12) var(--rubik-font-stack);
  font-feature-settings: 'liga' off;
}

.root_insideIframe body {
  background: transparent;
}

:where(dialog) {
  color: inherit;
}

:any-link {
  color: var(--color-primary-500);
  text-decoration: none;
}

* {
  overflow-wrap: anywhere;
}

h1,
h2,
h3,
h4,
h5,
h6,
address,
button,
select {
  font: inherit;
}

menu,
ul,
ol {
  list-style: none;
  padding: 0;
}

pre {
  max-width: 100%;
  overflow: auto;
}

img,
svg {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

button,
label,
select {
  cursor: pointer;
}

button:disabled,
input:disabled,
select:disabled,
textarea:disabled {
  cursor: not-allowed;
}

.children {
  height: inherit;
}

.root_insideIframe .children {
  transform: translate3d(0, 0, 0);
  position: absolute;
  inset: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  background: var(--color-base-01);
}
