.root {
  border: 1px solid var(--color-base-03);
  padding: 8px;
  border-radius: 8px;
  background: var(--color-base-01);
}

.root:not(.root_error):focus-within {
  border-color: var(--color-primary-500);
}

.root_error {
  color: var(--color-system-error);
  border-color: var(--color-system-error);
}

.input {
  width: 100%;
  color: inherit;
  font: var(--font-body-l);
  line-height: 22px;
}

:where(.input:not(.root)) {
  border: 0;
  padding: 0;
  background: none;
}

.input:focus-visible {
  outline: 0;
}

.input::placeholder {
  color: var(--color-base-06);
  opacity: 1;
}

.textarea {
  resize: none;
}
