.description {
  font: var(--font-body-l-b);
  text-align: center;
}

.alertPlate {
  display: grid;
  justify-items: center;
  gap: 16px;
  padding: 12px;
  border-radius: 12px;
  background: var(--color-base-01);
  margin-block-end: 20px;
}

.alertText {
  text-align: center;
  font: var(--font-body-m);
}

.alertCancelBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-block-start: 12px;
  width: 100%;
  border: 0;
  background: none;
  color: var(--color-primary-500);
  font: var(--font-body-l-b);
}

.accountItem {
  display: grid;
  grid-template-columns: 24px auto;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid var(--color-base-03);
  background: var(--color-base-00);
}

.accountTitle {
  font: var(--font-headline-h3);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.accountAvatarContainer {
  position: relative;
  flex-shrink: 0;
}

.accountAvatarContainer::after {
  display: none;
  content: '';
  position: absolute;
  right: -4px;
  bottom: -4px;
  width: 12px;
  height: 12px;
  border: 1px solid var(--color-base-00);
  border-radius: 50%;
  background: var(--color-base-01) center / 12px 12px no-repeat;
}

.accountAvatarBadge_ethereum::after {
  display: block;
  background-image: url(../../icons/ethereum.svg);
}

.accountAvatarBadge_waves::after {
  display: block;
  background-image: url(../../icons/waves.svg);
}

.accountAvatar {
  border-radius: 50%;
}
