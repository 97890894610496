@import '../core.css';

.vendorGroupList {
  margin-top: 40px;
  display: grid;
  gap: 32px;
}

.vendorAnchor {
  position: relative;
  top: -80px;
}

.vendorGroupHeader {
  margin-bottom: 20px;
  display: grid;
  column-gap: 8px;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'logo name total';
}

.vendorGroupAvatar {
  grid-area: logo;
  border: 1px solid var(--color-base-03);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.vendorGroupName {
  grid-area: name;
  font: var(--font-headline-h3);
}

.vendorGroupTotal {
  grid-area: total;
  font: var(--font-headline-h3);
}

.vendorProductGroups {
  display: grid;
  row-gap: 8px;
}

.mobileVendorProductGroups {
  display: none;
}

.balanceAsset {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.card {
  --container-padding-inline: 20px;

  padding-inline: var(--container-padding-inline);
  background-color: var(--color-base-00);
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  border-radius: 12px;
}

.productBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--color-primary-50);
  color: var(--color-primary-500);
  font: var(--font-body-l);
  margin-block: 12px;
}

.vendorGroupHeaderSkeleton {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.vendorGroupNameSkeleton {
  width: 148px;
  height: 24px;
}

.vendorGroupTotalSkeleton {
  width: 142px;
  height: 24px;
}

.cardSkeleton {
  padding-block: 12px;
}

.productBadgelSkeleton {
  width: 117px;
  height: 24px;
}

.productSkeleton {
  margin-top: 32px;
  height: 32px;
}
