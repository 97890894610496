.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noAccountsMessage {
  color: var(--color-base-06);
  text-align: center;
}

.accountList {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
