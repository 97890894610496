.passwordFormHeading {
  font: var(--font-headline-h2);
  text-align: center;
  margin-bottom: 20px;
}

.passwordForm {
  max-width: 360px;
  margin: auto;
  border-radius: 12px;
  background: var(--color-base-00);
  box-shadow: 0 4px 40px 0 rgba(150, 150, 166, 0.12);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.passwordFormDescription {
  text-align: center;
  font: var(--font-body-m);
  margin-bottom: 8px;
}

.passwordFormButton {
  margin-top: 8px;
}

.passwordFormTermsOfService {
  margin-top: 4px;
  text-align: center;
  font: var(--font-body-s);
  color: var(--color-base-07);
}
