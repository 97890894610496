.root {
  animation: root 1.4s linear infinite;
  fill: none;
  stroke: currentColor;
  color: var(--color-primary-500);
}

.circle {
  stroke-dasharray: var(--path-length);
  transform-origin: center;
  animation: circle 1.4s ease-in-out infinite;
}

@keyframes root {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0.75turn);
  }
}

@keyframes circle {
  0% {
    stroke-dashoffset: var(--path-length);
  }

  50% {
    stroke-dashoffset: calc(var(--path-length) / 4);
    transform: rotate(0.375turn);
  }

  100% {
    stroke-dashoffset: var(--path-length);
    transform: rotate(1.25turn);
  }
}
