@import '../core.css';

.menuSection {
  margin-top: 8px;
}

.menuSection_title {
  font: var(--font-body-s-b);
  color: var(--color-base-05);
}

.menuSection_list > li {
  margin-top: 8px;
}

.menuItem {
  display: inline-flex;
  align-items: center;
  background: unset;
  border: none;
  width: 100%;
  height: 36px;
  font: var(--font-body-l);
  color: var(--color-base-07);
  position: relative;
  gap: 12px;
  padding: 0 12px 0 0;
}

@media (--breakpoint-mobile) {
  .menuItem {
    padding-right: 22px;
  }
}

.menuItem:is(button:not(:disabled), :any-link):hover,
.menuItem_active {
  color: var(--color-primary-500);
}

.menuItem:not(:any-link) {
  cursor: not-allowed;
}

.menuItem_active.menuItem::after {
  content: '';
  position: absolute;
  top: 6px;
  bottom: 6px;
  right: 0;
  width: 2px;
  background: var(--color-primary-500);
  border-radius: 2px 0 0 2px;
}

.menuItem_icon {
  font-size: 24px;
}

.menuItem_badge {
  margin-left: auto;
  color: var(--color-primary-500);
  background: var(--color-primary-50);
  font: var(--font-body-s);
  padding: 1px 2px;
  border-radius: 4px;
}
