.productCell {
  display: flex;
  gap: 8px;
  align-items: center;
}

.productName {
  font: var(--font-body-m);
  color: var(--color-base-09);
}

.productLogo {
  font-size: 32px;
}

.protocolContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  font: var(--font-body-s);
  color: var(--color-base-06);
}

.protocolLogo {
  font-size: 10px;
}

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 2px;
}

.skeletonItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productCellSkeleton {
  width: 100%;
  max-width: 120px;
  height: 32px;
}

.totalSkeleton {
  width: 100%;
  max-width: 80px;
  height: 16px;
}
