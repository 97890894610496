.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font: var(--font-body-s);
  color: var(--color-base-06);
}

.button {
  border: 0;
  padding: 0;
  background: none;
  color: inherit;
}

.selectedValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.selectedValueUsd {
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--color-base-01);
}

.selectedValueTokens {
  color: var(--color-primary-500);
}

.selectedValueChevron {
  color: var(--color-primary-500);
  font-size: 24px;
}

.dialogCallToAction {
  margin-block-end: 20px;
  color: var(--color-base-07);
  font: var(--font-body-m);
}

.dialogFeeOptionList {
  display: grid;
  row-gap: 4px;
}

.dialogFeeOption {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--color-base-02);
  padding: 11px;
  width: 100%;
  border-radius: 12px;
  background: var(--color-base-01);
  color: inherit;
  text-align: start;
}

.dialogFeeOption:hover {
  border-color: var(--color-primary-100);
}

.dialogFeeOption.dialogFeeOption_selected {
  border-color: var(--color-primary-500);
}

.dialogFeeOptionLabel {
  font: var(--font-body-l);
}

.dialogFeeOptionValue {
  margin-inline-start: auto;
  text-align: end;
}

.dialogFeeOptionValueBlockchain {
  font: var(--font-body-m);
}

.dialogFeeOptionValueUsd {
  color: var(--color-base-06);
  font: var(--font-body-s);
}

.error {
  color: var(--color-system-error);
  font: var(--font-body-s);
}
