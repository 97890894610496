@import '../core.css';

.assetListContainer {
  --container-padding-inline: 20px;

  padding-inline: var(--container-padding-inline);
  background-color: var(--color-neutral-white);
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  border-radius: 12px;
  overflow: hidden;
}

@media (--breakpoint-mobile) {
  .assetListContainer {
    --container-padding-inline: 12px;
  }
}

.assetList {
  display: none;
}

@media (--breakpoint-mobile) {
  .assetTable {
    display: none;
  }

  .assetList {
    display: block;
  }
}

.totalValue {
  font: var(--font-body-s);
  color: var(--color-base-06);
}

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-block: 20px;
}

.skeletonItem {
  height: 24px;
}

@media (--breakpoint-mobile) {
  .skeleton {
    padding-top: 12px;
  }

  .skeletonItem {
    height: 38px;
  }
}
