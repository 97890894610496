@import '../core.css';

.table {
  border-spacing: 0;
  margin-inline: calc(var(--container-padding-inline, 0) * -1);
  width: calc(100% + calc(var(--container-padding-inline, 0) * 2));
  font: var(--font-body-m-b);
}

@media (--breakpoint-mobile) {
  .table {
    font: var(--font-body-s-b);
  }
}

.table td {
  table-layout: fixed;
}

.table :is(th, td):first-child {
  padding-left: var(--container-padding-inline, 20px);
}
.table :is(th, td):last-child {
  padding-right: var(--container-padding-inline, 20px);
}

.tableHead {
  background-color: var(--color-base-01);
}

.headCell {
  font: var(--font-body-s);
  text-align: left;
  padding: 12px 4px;
}

.row:hover {
  background: var(--color-base-01);
}

.clickable {
  cursor: pointer;
}

.cell {
  font: var(--font-body-m-b);
  padding: 12px 4px;
}

@media (--breakpoint-mobile) {
  .cell {
    font: var(--font-body-s-b);
  }
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}
