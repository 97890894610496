@import '../../core.css';

.heading {
  margin-block-end: 12px;
  font: var(--font-headline-h1);
  text-align: center;
}

@media (--breakpoint-mobile) {
  .heading {
    font: var(--font-headline-h2);
  }
}

.subheading {
  text-align: center;
  font: var(--font-body-l);
  color: var(--color-base-07);
}

@media (--breakpoint-mobile) {
  .subheadingBr {
    display: none;
  }
}

.introButtonList {
  max-width: 790px;
  margin-inline: auto;
  margin-top: 44px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 70px;
}

@media (--breakpoint-mobile) {
  .introButtonList {
    margin-top: 20px;
    justify-content: center;
    gap: 12px;
  }
}

.introButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 360px;
  border: 0;
  padding: 20px;
  box-shadow:
    inset 0 0 0 0 #b6c7f8,
    0 4px 40px rgba(150, 150, 166, 0.12);
  border-radius: 12px;
  background: linear-gradient(
      222.65deg,
      #e9efff -10.82%,
      rgba(233, 239, 255, 0) 54.09%
    ),
    #ffffff;
  color: inherit;
  text-align: start;
  transition: box-shadow 0.2s ease-in-out;
}

.introButton:hover,
.introButton:focus {
  box-shadow:
    inset 0 0 0 1px #b6c7f8,
    0 2px 4px rgba(39, 39, 50, 0.08);
}

.introButton:focus {
  outline: 0;
}

@media (--breakpoint-mobile) {
  .introButton {
    padding: 13px;
    width: 296px;
  }
}

.introButtonLogoContainer {
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  background: var(--color-primary-50);
}

.introButtonLogo {
  width: 48px;
}

@media (--breakpoint-mobile) {
  .introButtonLogoContainer {
    margin-bottom: 12px;
  }

  .introButtonLogo {
    width: 36px;
  }
}

.introButtonLabel {
  position: relative;
  font: var(--font-headline-h2);
  margin-bottom: 12px;
}

@media (--breakpoint-mobile) {
  .introButtonLabel {
    font: var(--font-headline-h3);
    margin-bottom: 8px;
  }
}

.introButtonDescription {
  position: relative;
  padding-inline-end: 30px;
  font: var(--font-body-l);
  color: var(--color-base-06);
}

@media (--breakpoint-mobile) {
  .introButtonDescription {
    padding-inline-end: 0;
  }
}
