@import '../core.css';

.cardList {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, 208px);
}

@media (--breakpoint-mobile) {
  .cardList {
    gap: 12px;
    grid-template-columns: repeat(auto-fill, 142px);
  }
}

.cardListItem {
  position: relative;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  background: var(--color-base-00);
  color: var(--color-base-09);
}

@media (--breakpoint-mobile) {
  .cardListItem {
    padding: 8px;
    border-radius: 8px;
  }
}

.cardImage {
  width: 184px;
  height: 184px;
  border-radius: 12px;
  object-fit: cover;
}

@media (--breakpoint-mobile) {
  .cardImage {
    width: 126px;
    height: 126px;
    border-radius: 4px;
  }
}

.cardCaption {
  margin-block-start: 12px;
  font: var(--font-body-l-b);
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (--breakpoint-mobile) {
  .cardCaption {
    margin-block-start: 8px;
    font: var(--font-body-s-b);
  }
}

.cardItemCount {
  position: absolute;
  inset-block-start: 20px;
  inset-inline-start: 20px;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--color-primary-50);
  color: var(--color-primary-500);
  font: var(--font-body-m);
}

@media (--breakpoint-mobile) {
  .cardItemCount {
    inset-block-start: 12px;
    inset-inline-start: 12px;
    padding: 0 2px;
    font: var(--font-body-s);
  }
}

.cardSkeleton {
  height: 239px;
}

@media (--breakpoint-mobile) {
  .cardSkeleton {
    height: 164px;
  }
}
