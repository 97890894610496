@import '../core.css';

.root {
  margin: 20px;
  max-width: 400px;
  width: calc(100% - 40px);
  border-radius: 12px;
  box-shadow: 0px 16px 24px 0px rgba(39, 39, 50, 0.12);
}

.header {
  display: grid;
  row-gap: 12px;
  border-radius: 12px 12px 0 0;
  padding: 20px;
  background: var(--color-base-01);
}

.headerTop {
  display: flex;
  justify-content: space-between;
}

.headerCloseButton {
  border: 0;
  padding: 0;
  background: none;
  color: var(--color-base-06);
  font-size: 24px;
}

.headerNetwork {
  display: flex;
  column-gap: 8px;
}

.headerNetworkIcon {
  border: 1px solid var(--color-base-03);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  object-fit: contain;
  background: var(--color-base-00);
}

.body {
  padding: 12px 20px 20px;
  border-radius: 0 0 12px 12px;
  background: var(--color-base-00);
}

.txDetails {
  margin-block-end: 12px;
  display: grid;
  row-gap: 12px;
  font: var(--font-body-s);
}

.txDetailsItem {
  display: flex;
  align-items: center;
  min-height: 24px;
}

.txDetailsItemLabel {
  margin-inline-end: 12px;
  color: var(--color-base-06);
}

.txDetailsItemValue {
  margin-inline-start: auto;
}

@media (--breakpoint-mobile) {
  .txAddress_lg {
    display: none;
  }
}

@media not (--breakpoint-mobile) {
  .txAddress_sm {
    display: none;
  }
}

.txInvokePayments {
  display: grid;
  row-gap: 8px;
}

.txInvokePaymentsItemSkeleton {
  width: 120px;
  height: 24px;
}

.txInvokePaymentsItem {
  display: flex;
  align-items: center;
  gap: 4px;
}

.waitingConfirmationMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border: 1px solid var(--color-base-03);
  padding: 8px 12px;
  border-radius: 12px;
  background: var(--color-base-01);
  font: var(--font-body-s);
}

.confirmationError {
  display: grid;
  row-gap: 12px;
}

.confirmationErrorMessage {
  color: var(--color-system-error);
  font: var(--font-body-s);
}
