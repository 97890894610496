/* Adapted from
* https://gwfh.mranftl.com/fonts/rubik?subsets=cyrillic%2Ccyrillic-ext%2Clatin%2Clatin-ext
* */

@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-weight: 400;
  src: url('./fonts/rubik-v26-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Rubik';
  font-weight: 500;
  src: url('./fonts/rubik-v26-cyrillic_cyrillic-ext_latin_latin-ext-500.woff2')
    format('woff2');
}
