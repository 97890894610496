.root {
  display: flex;
  padding: 2px;
  border-radius: 8px;
  background: var(--color-base-02);
}

.optionButton {
  flex: 1 1 0;
  border: 1px solid transparent;
  padding: 6px;
  border-radius: 6px;
  background: none;
  color: var(--color-base-07);
  font: var(--font-body-m-b);
}

.optionButton_selected {
  border-color: var(--color-base-03);
  background: var(--color-base-00);
  color: var(--color-base-09);
}
