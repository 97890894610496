@import '../core.css';

.root {
  max-width: 500px;
}

.blockchainSwitcher {
  margin-block-end: 20px;
}

.swapPageTitle {
  font: var(--font-headline-h1);
  margin-right: auto;
  margin-bottom: 20px;
}

@media (--breakpoint-mobile) {
  .swapPageTitle {
    font: var(--font-headline-h2);
  }
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-base-03);
  padding: 16px;
  min-height: 300px;
  border-radius: 12px;
  background: var(--color-base-01);
  text-align: center;
}
