@import '../core.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 480px;
}

.statusMessage {
  font: var(--font-headline-h3);
  text-align: center;
}

.plate {
  display: flex;
  flex-direction: column;
  background: var(--color-base-01);
  border: 1px solid var(--color-base-03);
  border-radius: 8px;
  padding: 12px;
  gap: 12px;
}

.accountRow {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font: var(--font-body-l);
  text-align: center;
}

.accountHelperText {
  font: var(--font-body-s);
  text-align: center;
  color: var(--color-base-06);
}

.recipientRow {
  font: var(--font-body-s);
  text-align: center;
}

.recipientLabel {
  color: var(--color-base-06);
}

.statusBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
}

.statusCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: var(--color-base-00);
  border: 1px solid var(--color-base-03);
  border-radius: 8px;
  padding: 12px;
}

.statusRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.statusErrorMessage {
  font: var(--font-body-s);
  color: var(--color-system-error);
}

.assetItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusIcon {
  font: var(--font-headline-h2);
  color: var(--color-system-success);
}

.assetIcon,
.statusIcon {
  flex-shrink: 0;
}

.assetAmountInTokens {
  word-break: break-all;
}

.assetAmountInUsd {
  font: var(--font-body-s);
  color: var(--color-base-07);
}

.transactionInfo {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding-block-end: 24px;
}

.explorerUrl {
  font: var(--font-body-s-b);
  color: var(--color-primary-500);
}
