@import '../core.css';

.root {
  background: var(--color-base-00);
}

.nav {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
