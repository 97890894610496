@import '../core.css';

.accountsPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.accountsPageTitle {
  font: var(--font-headline-h1);
  margin-right: auto;
}

@media (--breakpoint-mobile) {
  .accountsPageTitle {
    font: var(--font-headline-h2);
  }
}

.accountsPageControls {
  display: flex;
  gap: 20px;
  white-space: nowrap;
}

@media (--breakpoint-mobile) {
  .accountsPageControls {
    display: none;
  }
}

.mobileAddAccount {
  display: none;
}

.mobileAddAccountIcon {
  font: var(--font-headline-h2);
}

@media (--breakpoint-mobile) {
  .mobileAddAccount {
    display: inline-flex;
  }
}

.accountList {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
}

@media (--breakpoint-mobile) {
  .accountList {
    grid-template-columns: 1fr;
    gap: 12px;
  }
}

.accountListItem {
  display: grid;
  grid-template-areas: 'avatar title balance chevron';
  grid-template-columns: 44px 1fr auto auto;
  align-items: center;
  gap: 20px;
  padding: 24px 20px;
  background: var(--color-base-00);
  box-shadow: 0 4px 40px rgba(150, 150, 166, 0.12);
  border-radius: 12px;
  color: var(--color-base-99);
  transition: box-shadow 0.2s ease-in-out;
}

.accountListItem:hover {
  box-shadow: 0 2px 4px rgba(39, 39, 50, 0.08);
}

@media (--breakpoint-mobile) {
  .accountListItem {
    grid-template-areas: 'avatar title chevron' 'avatar balance chevron';
    grid-template-columns: 32px 1fr auto;
    gap: 8px;
    row-gap: 4px;
    padding: 12px;
  }
}

.accountAvatarContainer {
  grid-area: avatar;
  position: relative;
}

.accountAvatarContainer::after {
  display: none;
  content: '';
  position: absolute;
  right: -4px;
  bottom: -4px;
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-base-00);
  border-radius: 50%;
  background: var(--color-base-01) center / 24px 24px no-repeat;
}

@media (--breakpoint-mobile) {
  .accountAvatarContainer::after {
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
  }
}

.accountAvatar {
  --avatar-size: 44px;
}

@media (--breakpoint-mobile) {
  .accountAvatar {
    --avatar-size: 32px;
  }
}

.accountTitle {
  grid-area: title;
  font: var(--font-headline-h3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (--breakpoint-mobile) {
  .accountTitle {
    font: var(--font-body-l-b);
  }
}

.accountBalance {
  grid-area: balance;
  display: flex;
  align-items: center;
  font: var(--font-headline-h3);
  color: var(--color-base-07);
}

@media (--breakpoint-mobile) {
  .accountBalance {
    font: var(--font-body-s);
  }
}

.accountBalanceSkeleton {
  width: 116px;
  height: 19px;
}

.chevronRightIcon {
  grid-area: chevron;
  font: var(--font-headline-h2);
}

.accountsSpinnerWrapper {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noAccountsMessage {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
}

@media (--breakpoint-mobile) {
  .noAccountsMessage {
    padding-top: 0;
  }
}
