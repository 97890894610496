.logoContainer {
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--rubik-font-stack);
  line-height: 1;
  position: relative;
  background-color: var(--color-base-01);
  overflow: hidden;
  color: var(--color-neutral-white);
}

.logoImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
}
