.accountAvatarContainer {
  --size: var(--avatar-size, 32px);

  position: relative;
  width: var(--size);
  height: var(--size);
}

.accountAvatar {
  border-radius: 50%;
}

.accountAvatarBadge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-base-00);
  border: 1px solid var(--color-base-01);
  border-radius: calc(var(--size) * 0.1);
  width: calc(var(--size) * 0.54);
  height: calc(var(--size) * 0.54);
  right: calc(var(--size) * -0.09);
  bottom: calc(var(--size) * -0.09);
  font-size: calc(var(--size) * 0.47);
}
