.root::backdrop {
  background: transparent;
}

.spinnerWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.main {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
