.productCell {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 8px;
  align-items: center;
}

.productCellTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
